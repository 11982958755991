import Cookies from 'universal-cookie';

import { TRACKING_CONFIG } from '../constants/tracking';

const cookies = new Cookies();

/**
 * Calls the get function on the universal cookie.
 * @param {string} name
 */
export function getCookie(name: string) {
    try {
        return cookies.get(name);
    } catch (error) {
        return null;
    }
}

/**
 * Calls the set function on the universal cookie.
 * @param {string} name
 * @param {string} value
 * @param {Object} [options={}]
 * @param {string} [options.path]
 * @param {number} [options.daysUntilExpire]
 */

interface CookieOptions {
    path?: string;
    daysUntilExpire?: number;
}

export function setCookie(name: string, value: unknown, options: CookieOptions = {}): void {
    try {
        const path = options.path || '/';
        const daysUntilExpire = options.daysUntilExpire || TRACKING_CONFIG.DEFAULT_COOKIE_LIFE;

        const date = new Date();
        date.setTime(date.getTime() + daysUntilExpire * 24 * 60 * 60 * 1000);

        let domain = '';
        if (window.location.hostname.includes('stage.lendo.se')) {
            domain = 'stage.lendo.se';
        } else if (window.location.hostname.includes('lendo.se')) {
            domain = '.lendo.se';
        }

        cookies.set(name, value, { expires: date, path, domain });
    } catch (error) {
        console.error(error);
    }
}

export function removeCookie(name: string): void {
    try {
        cookies.remove(name);
    } catch (error) {
        console.error(error);
    }
}

export function resolveCookieDomain(): string | undefined {
    if (typeof window === 'undefined') {
        return undefined;
    } else if (window.location.host.indexOf('stage.lendo.se') !== -1) {
        return '.stage.lendo.se';
    } else if (window.location.host.indexOf('lendo.se') !== -1) {
        return '.lendo.se';
    } else {
        return undefined;
    }
}
