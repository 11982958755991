import { getCookie, setCookie } from './cookie-helper';

export function setDefaultConsentCookies(): void {
    const consentCookie = getCookie('consent');

    if (!consentCookie) {
        setCookie('consent', { product: true, marketing: false, necessary: true }, { daysUntilExpire: 365 });
    }
}

export function usercentricsConsentChange(event: CustomEvent<ConsentDetails>): void {
    const categories = event.detail.categories;

    if (event.detail.consent.type === 'EXPLICIT') {
        setCookie('seenCookieBanner', 'true', { daysUntilExpire: 365 });

        setCookie(
            'consent',
            {
                product: categories.functional.state === 'ALL_ACCEPTED',
                marketing: categories.marketing.state === 'ALL_ACCEPTED',
                necessary: categories.essential.state === 'ALL_ACCEPTED',
            },
            { daysUntilExpire: 365 }
        );
    }
}

if (typeof window !== 'undefined') {
    // @ts-expect-error Testing
    window.usercentricsConsentChange = usercentricsConsentChange;
}

export function createUsercentricsScript(): { __html: string } {
    return {
        __html: `

            window.UC_UI_DOMAINS = {
                crossDomainConsentSharingIFrame: 'https://stage.lendo.se/cross-domain-bridge.html',
            };

        `,
    };
}

// Taken from: https://usercentrics.com/docs/web/features/api/interfaces/

type ConsentActionType =
    | 'onAcceptAllServices'
    | 'onDenyAllServices'
    | 'onEssentialChange'
    | 'onInitialPageLoad'
    | 'onNonEURegion'
    | 'onSessionRestored'
    | 'onTcfStringChange'
    | 'onUpdateServices'
    | 'onMobileSessionRestore';

type ConsentType = 'IMPLICIT' | 'EXPLICIT';

type SettingType = 'TCF' | 'GDPR' | 'CCPA';

interface SettingData {
    id: string;
    type: SettingType;
    version: string;
    abVariant?: string;
    sandbox?: true;
}

interface ServiceData {
    name: string;
    version: string;
    category: string;
    essential: boolean;
    consent?: {
        given: boolean;
        type: 'IMPLICIT' | 'EXPLICIT';
    };
    gcm?: {
        analyticsStorage?: true;
        adStorage?: true;
    };
    subservices?: Record<string, ServiceData>;
    thirdCountryDataTransfer?: boolean;
    status?: 'added';
}

interface CategoryData {
    essential?: boolean;
    state: 'ALL_DENIED' | 'SOME_ACCEPTED' | 'ALL_ACCEPTED';
    dps: Record<string, boolean> | null;
    hidden?: boolean;
}

interface ConsentDetails {
    consent: ConsentData;
    services: Record<string, ServiceData>;
    categories: Record<string, CategoryData>;
}

interface ConsentData {
    status: 'ALL_ACCEPTED' | 'ALL_DENIED' | 'SOME_ACCEPTED' | 'SOME_DENIED';
    serviceIds?: string[];
    required: boolean;
    version: number;
    controllerId: string;
    language: string;
    createdAt: number;
    updatedAt: number;
    updatedBy: ConsentActionType;
    setting: SettingData;
    type: ConsentType;
    hash: string;
    gpcSignal?: boolean;
    isBot?: true;
    isOutsideEu?: true;
}

declare global {
    interface WindowEventMap {
        UC_CONSENT: CustomEvent<ConsentDetails>;
    }
}
